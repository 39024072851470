// Set your brand colors
$bgt-grey: #8c9b9d;
$bgt-grey-light: #a9afb7;
$bgt-grey-lighter: #dee2e5;
$bgt-orange: #E29E21;
$bgt-yellow: #f1b70e;
$bgt-green: #457E46;
$bgt-linkColor: #577A9F;
$bgt-blue: #2284A0;
$bgt-purple: #8e44ad;
$bgt-red: #934466;
$bgt-white-ter: #ecf0f1;
$bgt-primary: #34495e !default;
$bgt-yellow-invert: #fff;
$bgt-text: $bgt-primary;
$bgt-link: $bgt-linkColor;
$bgt-button-color: $bgt-primary;
$bgt-navbar-dropdown-background-color: $bgt-white-ter;
$bgt-navbar-divider-background-color: $bgt-grey-lighter;

// Path to Bulma's sass folder
@use "bulma/sass" with (
  $family-primary: '"Nunito", sans-serif',
  $primary: $bgt-primary,
  $link: $bgt-link,
  $danger: $bgt-red,
  $warning: $bgt-orange,
  $success: $bgt-green,
  $info: $bgt-blue,
  $control-border-width: 2px,
  $input-shadow: none,
  $navbar-background-color: $bgt-primary,
  //$navbar-height: 6em,
  $breadcrumb-item-separator-color: transparent,
  $breadcrumb-item-padding-horizontal: 0em,
);

$radius-small: 3px;
$radius: 0.4em;
$radius-large: 8px;

$size-7: 0.85em;
$title-weight: 500;
$subtitle-weight: 400;
$subtitle-color: darken($bgt-grey, 20);

$border-width: 2px;

$body-size: 15px;
$footer-background-color: $bgt-white-ter;

$text-light: lighten($bgt-primary, 10);
$text-strong: darken($bgt-primary, 5);

$box-color: $bgt-text;
$box-background-color: $bgt-white-ter;
$box-shadow: none;

$link-hover: darken($bgt-link, 10);
$link-focus: darken($bgt-link, 10);
$link-active: darken($bgt-link, 10);

$button-hover-color: darken($bgt-primary, 5); // primary-dark
$button-focus: darken($bgt-primary, 5); // primary-dark
$button-active-color: darken($bgt-primary, 5); // primary-dark

$navbar-background-color: $bgt-primary;
$navbar-item-color: $bgt-white-ter;
$navbar-item-hover-color: $bgt-link;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: $bgt-link;
$navbar-dropdown-arrow: #fff;
$navbar-dropdown-border-top: 1px solid $bgt-navbar-divider-background-color;
$navbar-dropdown-item-hover-color: $bgt-link;
$navbar-dropdown-item-hover-background-color: transparent;
$navbar-dropdown-item-active-background-color: transparent;
$navbar-dropdown-item-active-color: $bgt-link;

$control-height: 2.5em;



// Import the Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400;700");

